<template>
    <b-container class="pt-5 pb-5">
        <h1 class="mb-4">Copyright</h1>
        <p><strong>&copy; {{ new Date().getFullYear() }} Tixgo&trade; – Alle rechten voorbehouden.<br></strong><em><span class="ILfuVd" lang="nl"><span class="hgKElc">Alle prijzen zijn onder voorbehoud van druk – en zetfouten.</span></span></em></p> <p><strong>Tixgo is een handelsnaam van<br></strong><strong>LetsStay B.V.</strong><br>Meester B.M. Teldersstraat 7<br>6842 CT Arnhem<br>Nederland</p> <p>KvK: 691174636<br>BTW: NL857767409B01</p> <p>E-mail: contact@tixgo.nl<br>Persvoorlichter: pers@tixgo.nl</p>
    </b-container>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Copyright',
        }
    }
</script>